// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 12px;
  text-align: center;
}

th {
  background-color: #4CAF50;
  color: white;
}

.status-up {
  background-color: #28a745;
  color: white;
}

.status-down {
  background-color: #dc3545;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["/* src/App.css */\nbody {\n  font-family: Arial, sans-serif;\n  background-color: #f4f4f4;\n  margin: 0;\n  padding: 20px;\n}\n\nh1 {\n  text-align: center;\n  color: #333;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin: 20px 0;\n}\n\ntable, th, td {\n  border: 1px solid #ddd;\n}\n\nth, td {\n  padding: 12px;\n  text-align: center;\n}\n\nth {\n  background-color: #4CAF50;\n  color: white;\n}\n\n.status-up {\n  background-color: #28a745;\n  color: white;\n}\n\n.status-down {\n  background-color: #dc3545;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
