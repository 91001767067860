// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [servers, setServers] = useState([]);

  useEffect(() => {
    // Fetch server status from FastAPI
    const fetchServerStatus = async () => {
      try {
        const response = await axios.get('https://uptime.raith.one/status');
        setServers(response.data);
      } catch (error) {
        console.error('Error fetching server status:', error);
      }
    };

    // Fetch status initially
    fetchServerStatus();

    // Set up interval to refresh status every 30 seconds
    const interval = setInterval(fetchServerStatus, 30000);
    return () => clearInterval(interval); // Clear interval on unmount
  }, []);

  return (
    <div className="App">
      <h1>Server Status</h1>
      <table>
        <thead>
          <tr>
            <th>Server Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {servers.map((server, index) => (
            <tr key={index}>
              <td>{server.name}</td>
              <td className={server.status === 'Up' ? 'status-up' : 'status-down'}>
                {server.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
